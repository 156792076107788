<template>
  <main>
    <trac-loading v-if="loading" />
    <trac-modal v-if="successful" :cancel="false">
      <div class="flex flex-col justify-center items-center">
        <img src="@/assets/svg/success.svg" class="w-32 h-32 my-6 bg-white" alt="">

        <p class="text-center tracking-normal w-2/3 text-xs mb-6 -mt-2 px-8">
          {{message}}
        </p>

        <button
          @click="$router.push({name: 'KycProfile'})"
          class="bg-none border-none text-primaryBlue text-xs outline-none font-semibold">
          Back to KYC
        </button>
      </div>
    </trac-modal>
    <trac-back-button :backAction="backAction"> Back </trac-back-button>
    <p class="text-base text-gray-800 font-semibold mt-5 ml-3">
      Complete KYC by verifying your Address
    </p>
    <!-- What's class to horizontally center a flex element? -->
    <div
      class="w-full big-shadow bg-white mt-12 p-12 flex flex-col justify-center"
    >
      <div class="px-5 rounded-md flex-1 text-center">
        <img
          class="inline-block"
          src="@/assets/svg/location-marker.svg"
          alt=""
        />
      </div>
      <div class="text-center">
        <h2 class="text-lg font-extrabold text-blue-800">
          Provide your Address
        </h2>
        <p class="text-xs my-4">Hi! {{username}}, kindly add your details below.</p>
      </div>
      <div class="w-2/3 sm:w-full md:w-3/5 lg:w-2/5 mx-auto py-8">
        <vue-google-autocomplete
          id="map"
          :country="['ng']"
          classname="border smallest text-primaryBlue focus:outline-none border-primaryBlue leading-4 px-4 rounded inline-flex justify-between capitalize items-center min-w-full w-60 py-2"
          placeholder="Enter Your Address’"
          v-on:placechanged="getAddressData"
        />
        <trac-upload
          :placeholder-text="'Upload Store Front Image'"
          :view="'documentInput'"
          @uploadedFile="getFileUrl($event, 'addressProof')"
        />
        <trac-dropdown
          :title="'Select ID Type'"
          class="w-full mt-6"
          v-model="selectedUtility"
          :menuItems="utilityOptions"
        ></trac-dropdown>
        <trac-upload
          :placeholder-text="'Upload Utility Bill Document'"
          :view="'documentInput'"
          @uploadedFile="getFileUrl($event, 'utilityBillUrl')"
          :allowed-types="['image/png', 'image/jpeg', 'application/pdf']"
        />
        <trac-input
          v-if="showMeterNo"
          class="mt-6"
          v-model="idNumber"
          :placeholder="'Meter No'"
        ></trac-input>
        <div class="text-right mt-6">
          <trac-button :disabled="!formCheck.valid" @button-clicked="sendDetails()" class="uppercase">Continue</trac-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_DATA, GET_USER_DATA } from "../../browser-db-config/localStorage";
import {eventBus} from '../../eventBus';
export default {
  data() {
    return {
      loading: false,
      utilityOptions: [
        { name: "Electricity", value: "electricity" },
        { name: "Others", value: "others" },
      ],
      username: "",
      message:"",
      selectedUtility: "",
      isCustomKycRoute: false,
      email: "",
      utilityBillUrl: "",
      address: "",
      addressProof: "",
      idNumber: '',
      latlong: {
        latitude: 0,
        longitude: 0
      },
      successful: false
    };
  },
  mounted() {
    const user = GET_USER_BUSINESS_DATA()
    this.username = GET_USER_DATA().name
    this.email = user.email;
    this.isCustomKycRoute = this.$route.query.custom || false;
  },
  methods: {
    getAddressData(data) {
      console.log("data from autocomplete, ", data);
      this.address = data.route
    },
    backAction() {
      this.$router.replace({
        name: "KycProfile",
        params: { custom: this.isCustomKycRoute },
      });
    },
    getFileUrl(url, dataProperty) {
      console.log("url", url);
      this.$set(this, dataProperty, url);
    },
    async sendDetails() {
      this.loading = true;
      this.$store.dispatch("VERIFY_ADDRESS", this.payload).then((res) => {
        if(res.status) {
          this.successful = true;
          this.loading = false;
          this.message = res.message;
        } else {
          this.successful = false;
          eventBus.$emit("trac-alert", {
            message: res.message || "An error occurred",
          });
          this.loading = false;
        }
      });
    },
  },
  computed: {
    showMeterNo() {
      return this.selectedUtility.toLowerCase() === "electricity".toLowerCase()
    },
    formCheck() {
      const list = {
        address: this.address.length > 0 && typeof this.address === "string",
        addressProof: this.addressProof.length > 0 && typeof this.addressProof === "string",
        utilityBillUrl: this.utilityBillUrl.length > 0 && typeof this.utilityBillUrl === "string",
        selectedUtility: this.selectedUtility.length > 0 && typeof this.selectedUtility === "string",
      };
      if (this.showMeterNo) {
        list.idNumber = this.idNumber.length > 0 && typeof this.idNumber === "string";
      }
      return {
        valid: Object.values(list).every((item) => item === true),
        rules: list,
      };
    },
    payload() {
      return {
        idType: this.selectedUtility,
        idNumber: this.address,
        utility_id_number: this.idNumber,
        selfieUrl:this.addressProof,
        documentImageUrl:this.utilityBillUrl,
        address_geo_location: {
          latitude: this.latlong.latitude,
          longitude: this.latlong.longitude
        }
      };
    },
  },
};
</script>